@import url("https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,400;0,700;1,300&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Spectral:wght@400&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Rubik+Wet+Paint:wght@400&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Bebas+Neue:wght@400&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Pangolin:wght@400&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Literata:wght@400&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lato:wght@400&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Alegreya:wght@400&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Old+Standard+TT:wght@400&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Alice:wght@400&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Cormorant:wght@400&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Bellota:wght@400&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Bebas+Neue&family=Creepster&display=swap");

@font-face {
  font-family: "Courier New";
  src: url("/public/couriernew.ttf");
  font-weight: 400;
}
@font-face {
  font-family: "Ghastly Panic Cyr";
  src: url("/public/GhastlyPanicCyr.otf");
  font-weight: 400;
}
@font-face {
  font-family: "Needleteeth SP";
  src: url("/public/NeedleteethSP_0.otf");
  font-weight: 400;
}

@font-face {
  font-family: "Bookman Old Style";
  src: url("/public/bookmanoldstyle.ttf");
  font-weight: 400;
}

@tailwind base;
@tailwind components;
@tailwind utilities;
body {
  @apply leading-[normal] m-0;
}
*,
::before,
::after {
  border-width: 0;
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.navbar-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  position: absolute;
  width: 100%;
  top: 0;
  z-index: 1000;
}

.booking-text {
  width: 35%;
  font-size: smaller;
  color: #ff362f; /* Красноватый оттенок */
  background-color: rgba(255, 255, 255, 0.7); /* Белый полупрозрачный фон */
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 10px;
  font-family: "Arial";
  border-radius: 5px;
  font-weight: bold;
}

.booking-text a {
  color: #ff362f; /* Красноватый оттенок */
  text-decoration: none;
}

.booking-text a:hover {
  text-decoration: underline;
}
.nightmare-card {
  transition: transform 0.3s ease-in-out;
}

.nightmare-card.focused {
  transform: scale(1.2);
}

.footers {
  text-decoration: none;
  color: inherit;
}

@media (min-width: 770px) and (max-width: 1420px) {
  .textmain {
    font-size: 45px;
    padding-bottom: 100px;
  }
  .textadd {
    font-size: 25px;
  }
}

@media (max-width: 769px) {
  .textmain {
    font-size: 35px;
  }
  .textadd {
    font-size: 20px;
  }
}

@media (min-width: 920px) {
  .content {
    width: 40%;
  }
}

@media (max-width: 850px) {
  .content {
    font-size: 20px;
  }
}

@media (min-width: 400px) {
  .content {
    font-size: 15px;
  }
}

@media (max-width: 850px) {
  .imagebg1 {
    height: 1300px;
  }
}

.flex-wrap {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
}

@media (max-width: 1190px) {
  .poscards {
    margin: 180px;
  }
}

@media (min-width: 1190px) {
  .nightmare-card {
    padding-bottom: 250px;
  }
}

/* Изменения для отображения карточек кошмаров при различных ширинах экрана */
@media (min-width: 1000px) {
  .nightmare-card {
    flex-basis: calc(100% / 4 - 50px); /* 4 кошмара в строке */
  }
}

@media (max-width: 999px) and (min-width: 700px) {
  .nightmare-card {
    flex-basis: calc(100% / 2 - 32px); /* 2 кошмара в строке */
  }
}

@media (max-width: 699px) {
  .nightmare-card {
    flex-basis: calc(100% - 32px); /* 1 кошмар в строке */
  }
}

/* Добавляем стили для отзывов */
.testimonials-section {
  width: 50%; /* Делаем разделение на две колонки на больших экранах */
  float: left;
}

.quotes {
  width: 50%;
  float: right;
}

.quote-container {
  position: relative;
  width: 100%;
  margin-bottom: 20px; /* Добавляем отступ между отзывами */
}

/* Медиа-запрос для адаптивности */
@media screen and (max-width: 1280px) {
  .testimonials-section,
  .quotes {
    width: 100%; /* Один столбец на меньших экранах */
    float: none; /* Сбрасываем float для отзывов */
    margin-top: 10px;
  }
}

/* Стили для отображения отзывов как прямоугольников */
.quote-container {
  border: 1px solid #ccc;
  padding: 10px;
}

@media (max-width: 1790px) {
  .nightmare-header {
    font-size: 28px;
    margin-top: 80px;
  }
}

@media (max-width: 1479px) {
  .nightmare-header {
    margin-bottom: -10px;
    font-size: 25px;
  }
  .nightmare-description {
    font-size: 13px;
  }
  .nightmare-photo {
    width: 500px;
  }
}

@media (min-width: 1020px) and (max-width: 1320px) {
  .nightmare-header {
    font-size: 25px;
    margin-bottom: -20px;
  }
}

@media (max-width: 960px) {
  .nightmare-header {
    padding-bottom: 150px;
    font-size: 19px;
    height: 120px;
    padding-bottom: 20px;
    margin-left: 350px;
    top: 0;
  }
  .nightmare-description {
    font-size: 10px;
    margin-left: 350px;
    width: 220px;
    margin-top: -50px;
    padding-bottom: 50px;
  }
  .nightmare-photo {
    width: 350px;
    height: 400px;
    margin-bottom: -170px;
  }
  .buttonn {
    margin-left: 350px;
    width: 220px;
    padding-bottom: 80px;
    margin-bottom: 50px;
  }
  .bgrectancgle {
    height: 400px;
  }
}

@media (max-width: 768px) {
  .nightmare-header {
    padding-bottom: 10px;
    font-size: 17px;
    height: 150px;
    padding-bottom: 50px;
    margin-left: 300px;
    top: 0;
  }
  .nightmare-description {
    font-size: 9px;
    margin-left: 300px;
    width: 220px;
    margin-top: -50px;
    padding-bottom: 50px;
  }
  .nightmare-photo {
    width: 350px;
    height: 400px;
    margin-left: -50px;
    margin-bottom: -170px;
  }
  .buttonn {
    margin-left: 300px;
    width: 220px;
    padding-bottom: 80px;
    margin-bottom: 50px;
  }
  .bgrectancgle {
    height: 400px;
    width: 600px;
    left: 100px;
  }
}

@media (min-width: 320px) {
  .nightmare-header {
    padding-bottom: 150px;
    font-size: 13px;
    height: 100px;
    margin-left: -10px;
    top: 0;
    width: 180px;
  }
  .nightmare-description {
    font-size: 9px;
    margin-left: -10px;
    width: 140px;
    margin-bottom: 150px;
    width: 250px;
  }
  .nightmare-photo {
    width: 150px;
    height: 150px;
    margin-left: -15px;
    margin-bottom: -430px;
  }
  .buttonn {
    margin-left: 50px;
    width: 100px;
    height: 50px;
    margin-bottom: -50px;
  }
  .bgrectancgle {
    height: 400px;
    width: 260px;
    left: 50px;
    padding: auto;
  }
}

@media (min-width: 370px) {
  .nightmare-photo {
    width: 150px;
    height: 150px;
    margin-left: -25px;
    margin-bottom: -430px;
  }
  .nightmare-description {
    padding-right: 10px;
  }
}

@media (min-width: 420px) {
  .nightmare-header {
    padding-bottom: 150px;
    font-size: 13px;
    height: 100px;
    margin-left: -10px;
    top: 0;
    width: 250px;
  }
  .nightmare-description {
    font-size: 9px;
    margin-left: -10px;
    width: 140px;
    margin-bottom: 150px;
    width: 250px;
  }
  .nightmare-photo {
    width: 150px;
    height: 150px;
    margin-left: -35px;
    margin-bottom: -425px;
  }
  .buttonn {
    margin-left: 50px;
    width: 100px;
    height: 50px;
    margin-bottom: -50px;
  }
  .bgrectancgle {
    height: 400px;
    width: 275px;
    left: 50px;
    padding: auto;
  }
}

@media (min-width: 680px) {
  .nightmare-header {
    padding-bottom: 0px;
    font-size: 16px;
    height: 100px;
    margin-left: -60px;
    top: 0;
    width: 450px;
  }
  .nightmare-description {
    font-size: 11px;
    margin-left: -60px;
    width: 240px;
    margin-bottom: 70px;
    width: 500px;
  }
  .nightmare-photo {
    width: 150px;
    height: 150px;
    margin-left: -105px;
    margin-bottom: -430px;
  }
  .buttonn {
    margin-left: 220px;
    width: 150px;
    height: 50px;
    margin-bottom: 50px;
  }
  .bgrectancgle {
    height: 350px;
    width: 550px;
    left: 50px;
    padding: auto;
  }
}

@media (min-width: 860px) {
  .nightmare-header {
    padding-bottom: 0px;
    font-size: 18px;
    height: 100px;
    margin-left: 200px;
    top: 0;
    width: 400px;
  }
  .nightmare-description {
    font-size: 13px;
    margin-left: 200px;
    width: 240px;
    margin-bottom: 20px;
    width: 400px;
  }
  .nightmare-photo {
    width: 300px;
    height: 400px;
    margin-left: -120px;
    margin-bottom: -200px;
  }
  .buttonn {
    margin-left: 320px;
    width: 180px;
    height: 70px;
    margin-bottom: 50px;
  }
  .bgrectancgle {
    height: 350px;
    width: 750px;
    left: 50px;
    padding: auto;
  }
}

@media (min-width: 1020px) {
  .nightmare-header {
    padding-top: 70px;

    font-size: 18px;
    height: 100px;
    margin-left: 140px;
    top: 0;
    width: 600px;
  }
  .nightmare-description {
    font-size: 13px;
    margin-left: 250px;
    width: 370px;
    margin-bottom: 70px;
    width: 400px;
  }
  .nightmare-photo {
    width: 300px;
    height: 400px;
    margin-left: -155px;
    margin-bottom: -200px;
  }
  .buttonn {
    margin-left: 320px;
    width: 180px;
    height: 70px;
    margin-bottom: 50px;
  }
  .bgrectancgle {
    height: 370px;
    width: 900px;
    left: 50px;
    padding: auto;
  }
}

@media (min-width: 1279px) {
  .nightmare-header {
    padding-top: 70px;
    font-size: 18px;
    height: 100px;
    margin-left: 240px;
    top: 0;
    width: 600px;
  }
  .nightmare-description {
    font-size: 13px;
    margin-left: 250px;
    width: 370px;
    margin-bottom: 70px;
    width: 400px;
  }
  .nightmare-photo {
    width: 320px;
    height: 450px;
    margin-left: -205px;
    margin-bottom: -150px;
  }
  .buttonn {
    margin-left: 250px;
    width: 350px;
    height: 70px;
    margin-bottom: 50px;
  }
  .bgrectancgle {
    height: 370px;
    width: 1200px;
    left: 50px;
    padding: auto;
  }
}

@media (min-width: 1439px) {
  .nightmare-header {
    padding-top: 70px;
    font-size: 25px;
    height: 100px;
    margin-left: 240px;
    top: 0;
    width: 600px;
  }
  .nightmare-description {
    font-size: 16px;
    margin-left: 250px;
    width: 370px;
    margin-bottom: 70px;
    width: 400px;
  }
  .nightmare-photo {
    width: 420px;
    height: 550px;
    margin-left: -238px;
    margin-bottom: -60px;
  }
  .buttonn {
    margin-left: 250px;
    width: 350px;
    height: 70px;
    margin-bottom: 50px;
  }
  .bgrectancgle {
    height: 470px;
    width: 1300px;
    left: 50px;
    padding: auto;
  }
}

@media (min-width: 1709px) {
  .nightmare-header {
    padding-top: 70px;
    font-size: 26px;
    height: 100px;
    margin-left: 350px;
    top: 0;
    width: 650px;
  }
  .nightmare-description {
    font-size: 16px;
    margin-left: 350px;
    width: 370px;
    margin-bottom: 70px;
    width: 440px;
  }
  .nightmare-photo {
    width: 420px;
    height: 550px;
    margin-left: -320px;
    margin-bottom: -60px;
  }
  .buttonn {
    margin-left: 350px;
    width: 350px;
    height: 70px;
    margin-bottom: 50px;
  }
  .bgrectancgle {
    height: 470px;
    width: 1500px;
    left: 50px;
    padding: auto;
  }
}

@media (min-width: 1919px) {
  .nightmare-header {
    padding-top: 70px;
    font-size: 28px;
    height: 100px;
    margin-left: 240px;
    top: 0;
    width: 1000px;
  }
  .nightmare-description {
    font-size: 20px;
    margin-left: 250px;
    width: 370px;
    margin-bottom: 70px;
    width: 700px;
  }
  .nightmare-photo {
    width: 480px;
    height: 600px;
    margin-left: -333px;
    margin-bottom: -20px;
  }
  .buttonn {
    margin-left: 250px;
    width: 350px;
    height: 70px;
    margin-bottom: 50px;
  }
  .bgrectancgle {
    height: 500px;
    width: 1800px;
    left: 50px;
    padding: auto;
  }
}

.imagebg1 {
  width: 100%;
  height: auto;
  max-width: 100%;
  object-fit: cover;
  object-position: left;
}

@media (max-width: 480px) {
  .specialties {
    font-size: large;
  }
}

@media (max-width: 670px) {
  .footer {
    height: 2260px;
  }
}

@media (min-width: 1020px) {
  .footer {
    height: 1500px;
  }
}

@media (min-width: 768px) {
  .footer {
    height: 2100px;
  }
  .footer-container {
    bottom: 35%;
  }
}

@media (min-width: 671px) and (max-width: 765px) {
  .footer {
    height: 2100px;
  }
}

.hide-menu {
  opacity: 0;
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.5s ease-in-out, opacity 0.5s ease-in-out;
}

.show-menu {
  opacity: 1;
  max-height: 1000px; /* Максимальная высота бургер-меню */
  transition: max-height 0.5s ease-in-out, opacity 0.5s ease-in-out;
}

.faq-card {
  border: none;
  border-radius: 10px;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
}

.faq-card .card-header {
  background-color: #f8f9fa;
  border: none;
  padding: 15px;
  cursor: pointer;
}

.faq-card .card-header:hover {
  background-color: #e9ecef;
}

.faq-card .card-header span {
  font-weight: bold;
}

.faq-card .card-body {
  border-top: 1px solid #dee2e6;
  padding: 15px;
}

.faq-card .card-body p {
  margin-bottom: 0;
}

@media (max-width: 768px) {
  .faq-card .card-header {
    padding: 10px;
  }

  .faq-card .card-body {
    padding: 10px;
  }
}

/* Контейнер таблицы */
.time-table-container {
  overflow-x: auto; /* Добавляем горизонтальную прокрутку, если таблица не помещается по ширине */
  padding: 20px;
  background-color: #121212; /* Темный фон */
  border-radius: 10px; /* Закругленные углы */
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5); /* Тень для объема */
}

/* Сама таблица */
.time-table {
  width: 100%; /* Занимает 100% ширины контейнера */
  border-collapse: collapse; /* Объединяем границы ячеек */
  text-align: center; /* Выравнивание содержимого по центру */
  color: #f1f1f1; /* Светлый цвет текста */
}

/* Заголовки столбцов */
.time-table th {
  background-color: #2d2d2d; /* Темно-серый фон */
  color: #f1f1f1; /* Светлый цвет текста */
  padding: 15px; /* Отступ вокруг содержимого */
  font-size: 1.1em; /* Увеличенный размер шрифта */
}

/* Ячейки таблицы */
.time-table td {
  padding: 15px; /* Отступ вокруг содержимого */
  border: 1px solid #444; /* Границы ячеек */
}

/* Четные строки */
.time-table tbody tr:nth-child(even) {
  background-color: #3a3a3a; /* Темный фон для четных строк */
}

/* Нечетные строки */
.time-table tbody tr:nth-child(odd) {
  background-color: #282828; /* Темный фон для нечетных строк */
}

/* Стили для ячеек с ценой */
.time-table td {
  color: #ff4d4d; /* Красный цвет текста */
  font-weight: bold; /* Жирный текст */
}

/* Адаптивность на мобильных устройствах */
@media screen and (max-width: 600px) {
  .time-table {
    overflow-x: auto; /* Добавляем горизонтальную прокрутку на мобильных устройствах */
  }

  .time-table th,
  .time-table td {
    padding: 2px; /* Уменьшаем отступы для мобильных устройств */
  }
}

/* Стили для раздела "Экстремальный режим" */
.extreme-mode-container {
  background-color: #1c1c1c; /* Темный фон */
  padding: 20px;
  margin: 40px auto; /* Отступы вокруг, авто для центрирования */
  border-radius: 10px; /* Закругленные углы */
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5); /* Тень для объема */
  max-width: 800px; /* Ограничение ширины для лучшего восприятия */
}

.extreme-mode-title {
  color: #ff4d4d; /* Красный цвет текста */
  font-size: 36px; /* Размер шрифта */
  font-weight: bold; /* Жирный текст */
  margin-bottom: 15px; /* Отступ снизу */
  text-align: center; /* Центрирование текста */
  font-family: "Creepster", cursive; /* Используем шрифт Creepster */
}

.extreme-mode-description {
  color: #f1f1f1; /* Светлый цвет текста */
  font-size: 18px; /* Размер шрифта */
  line-height: 1.6; /* Междустрочный интервал */
  letter-spacing: 1px;
  text-align: center; /* Центрирование текста */
  margin-top: 10px; /* Отступ сверху */
  font-family: Georgia, "Times New Roman", Times, serif;
}

/* global.css */
.footer-container {
  background-color: #110f2079; /* Темный фон для футера */
  color: #fff; /* Белый текст */
  padding: 20px 0; /* Внутренние отступы */
}

.location-info {
  margin-bottom: 20px; /* Отступ снизу */
}

.hours {
  margin-top: 10px; /* Отступ сверху */
}

/* Стили для ссылок в футере */
.footers {
  color: #fff; /* Белый цвет текста */
  text-decoration: none; /* Убираем подчеркивание */
  transition: color 0.3s; /* Плавный переход цвета */
}

.footers:hover {
  color: #ffc107; /* Желтый цвет при наведении */
}

@media (min-width: 320px) {
  .footer-container {
    bottom: 40%;
  }
}

@media (min-width: 370px) {
  .footer-container {
    bottom: 38%;
  }
}

@media (min-width: 420px) {
  .footer-container {
    bottom: 35%;
  }
}
