.accordion {
  list-style-type: none;
  margin: 16px;
  padding: 16px;
  border-radius: 7px;
}
.accordion-item {
  margin-bottom: 16px;
  border-radius: 7px;
  background: none;
  overflow: hidden;
}

.accordion-header {
  position: relative;
  width: 100%;
  padding: 20px 60px 20px 30px;
  --tw-text-opacity: 1;
  color: rgb(255 181 72 / var(--tw-text-opacity));
  border: 0;
  border-radius: 7px;
  font-size: 36px;
  width: 100%;
  text-align: left;
  font-family: Alegreya;
  background: none;
  cursor: pointer;
}
.accordion-body {
  padding: 30px 60px 30px 30px;
  border-bottom-left-radius: 7px;
  border-bottom-right-radius: 7px;
  font-size: 12px;
  color: white;
}

@media (min-width: 671px) {
  .accordion-body {
    font-size: 20px;
  }
}

.accordion-collapse {
  height: 0;
  overflow: hidden;
  transition: height 0.3s;
}

.accordion-collapse.open {
  height: auto;
}

@media (max-width: 769px) {
  .accordion-header {
    font-size: 25px;
  }
}
