.reviews-container {
  width: 100%;
  height: 70%;
  margin: 0 auto;
  padding: 40px 40px;
  background: linear-gradient(135deg, #f5f7fa 0%, #c3cfe2 100%);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
  border-radius: 12px;
}

.reviews-title {
  font-size: 32px;
  text-align: center;
  margin-bottom: 40px;
  color: #ffecec;
  text-transform: uppercase;
  letter-spacing: 1.5px;
}

.video-gallery .slick-prev,
.video-gallery .slick-next {
  background-color: #333;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  z-index: 1;
}

.video-gallery .slick-prev:hover,
.video-gallery .slick-next:hover {
  background-color: #555;
}

.slick-slide > div {
  margin: 0; /* Убираем все внутренние отступы */
  padding: 0;
}

.slick-list {
  overflow: hidden;
  padding: 0; /* Убираем любые внутренние отступы */
  margin: 0; /* Убираем внешние отступы */
}

.video-item {
  position: relative;
  padding-top: 56.25%; /* Соотношение сторон 16:9 для iframe */
  overflow: hidden;
  border-radius: 12px;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.video-item iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: none; /* Убираем любые границы */
  margin: 0; /* Убираем любые отступы */
  padding: 0; /* Убираем любые внутренние отступы */
}

.reviewer-name {
  margin-top: 15px;
  text-align: center;
  font-style: italic;
  color: #666;
  font-size: 18px;
  transition: color 0.3s ease;
}

@media (max-width: 768px) {
  .reviews-container {
    padding: 30px 15px;
  }

  .reviews-title {
    font-size: 28px;
  }

  .video-item {
    padding-top: 56.25%; /* Соотношение сторон остается 16:9, чтобы избежать искажений */
  }

  .reviewer-name {
    font-size: 16px;
  }
}
